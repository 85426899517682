export class DateTimeProvider {
  private static instance: DateTimeProvider;
  private mockTime: number | null = null;

  private constructor() {}

  static getInstance(): DateTimeProvider {
    if (!DateTimeProvider.instance) {
      DateTimeProvider.instance = new DateTimeProvider();
    }
    return DateTimeProvider.instance;
  }

  now(): number {
    return this.mockTime ?? Date.now();
  }

  nowDate(): Date {
    return new Date(this.mockTime ?? this.now());
  }

  getDate(date: number | string | Date): Date {
    return new Date(date);
  }

  // For testing purposes
  setMockTime(time: number | null): void {
    this.mockTime = time;
  }
}

export const dateTimeProvider = DateTimeProvider.getInstance();
