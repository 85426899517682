import { useEffect, useMemo } from 'react';
import Logger from 'idb-logger';

export enum LogLevel {
  DEBUG = 3,
  INFO = 2,
  WARN = 1,
  ERROR = 0,
}

const DEFAULT_LOG_LEVEL = LogLevel.DEBUG;

interface LoggerOptions {
  id?: string;
  maxRecords?: number;
  logLevel?: LogLevel;
  onReport?: (data: any) => void;
  onDiscard?: (data: any) => void;
}

interface DownloadOptions {
  name?: string;
  filter?: (log: any) => boolean;
}

export function useLogger(options: LoggerOptions = {}) {
  const thisLogLevel = options.logLevel ?? DEFAULT_LOG_LEVEL;
  const logger = useMemo(() => {
    return new Logger({
      id: options.id ?? 'app-logs',
      useConsole: true,
      storeType: 'idb',
      maxRecords: options.maxRecords ?? 10000,
      onReport: options.onReport,
      onDiscard: options.onDiscard,
    });
  }, [options.id, options.maxRecords, options.onReport, options.onDiscard]);

  useEffect(() => {
    // Cleanup when component unmounts
    return () => {
      logger.close();
    };
  }, [logger]);

  const loggerApi = useMemo(
    () => ({
      // Basic logging methods
      log: async (...args: any[]) => {
        if (thisLogLevel >= LogLevel.DEBUG) {
          return logger.log(...args);
        }
      },
      error: async (...args: any[]) => {
        return logger.error(...args);
      },
      warn: async (...args: any[]) => {
        if (thisLogLevel >= LogLevel.WARN) {
          return logger.warn(...args);
        }
      },
      info: async (...args: any[]) => {
        if (thisLogLevel >= LogLevel.INFO) {
          return logger.info(...args);
        }
      },

      // Utility methods
      downloadLogs: async (options: DownloadOptions = {}) => {
        const logs = await logger.getAll();
        if (!logs || logs.length === 0) {
          console.warn('No logs found to download');
          return;
        }

        const filteredLogs = options.filter ? logs.filter(options.filter) : logs;
        if (filteredLogs.length === 0) {
          console.warn('No logs match the filter criteria');
          return;
        }

        const blob = new Blob([JSON.stringify(filteredLogs, null, 2)], {
          type: 'application/json',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = options.name ?? `app-logs-${Date.now()}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      clearLogs: async () => logger.clear(),
      getAllLogs: async () => logger.getAll(),
      getLogCount: async () => logger.count(),

      // Search/filter logs
      filterLogs: async (filter: any) => logger.filter(filter),
    }),
    [logger]
  );

  return loggerApi;
}
