import { createContext, useContext, ReactNode } from 'react';
import { useLogger } from './useLogger';

const LoggerContext = createContext<ReturnType<typeof useLogger> | null>(null);

export function LoggerProvider({
  children,
  options,
}: {
  readonly children: ReactNode;
  readonly options?: Parameters<typeof useLogger>[0];
}) {
  const logger = useLogger(options);

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
}

// Hook to use logger anywhere in the app
export function useAppLogger() {
  const logger = useContext(LoggerContext);
  if (!logger) {
    throw new Error('useAppLogger must be used within LoggerProvider');
  }
  return logger;
}
