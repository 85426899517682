import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import axios from '../../../../utils/axios';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { Card, Container, SelectChangeEvent, Table, TableContainer } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import { FilterType } from '../../../../@types/filters';
import Filters from 'components/_dashboard/filters/Filters';
import ListHead from 'components/_dashboard/list/ListHead';
import ListToolbar from 'components/_dashboard/list/ListToolbar';
import TableWrapper from 'components/_dashboard/table/TableWrapper';
import Scrollbar from 'components/Scrollbar';
import TableFooter from 'components/_dashboard/list/TableFooter';
import { COMPETITION_SEARCH_TABLE_HEAD } from 'utils/constants/table-headings';
import { initialPagination } from 'utils/constants/pages';
import {
  CompetitionSearchFilter,
  CompetitionSearchSortByQuery,
  FullCompetition,
} from '../../../../@types/competition';
import CompetitionSearchTable from './CompetitionSearchTable';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { Sorting } from '../../../../@types/sorting';
import { createRequestQuery } from 'utils/helperFiles';
import { calculatePages } from 'utils/pages';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useParsedQuery } from 'hooks/useParsedQuery';
import { debounce } from 'lodash';
import { dateTimeProvider } from '../../../../utils/dateTimeProvider';

function tryParseDate(dateString: string): string | null {
  const date = dateTimeProvider.getDate(dateString);
  return isNaN(date.getTime()) ? null : date.toISOString();
}

const fetchData = async ({
  filters,
  limit,
  page,
  sorting,
  search,
  startDate,
  endDate,
}: {
  filters: CompetitionSearchFilter;
  limit: number;
  page: number;
  sorting: Sorting;
  search?: string;
  startDate?: string;
  endDate?: string;
}): Promise<FullCompetition[]> => {
  const filtersQuery = createRequestQuery(
    {
      ...filters,
      limit,
      page,
      startDate,
      endDate,
      search,
    },
    'filters'
  );

  const sortQuery = createRequestQuery(
    {
      [`${sorting.orderBy}` as keyof CompetitionSearchSortByQuery]: [`${sorting.order}`],
    },
    'sortBy'
  );

  const requestQuery = (filtersQuery + sortQuery).slice(1);

  const response = await axios.get(
    `${window.BACK_OFFICE_CLIENT_ENV.API_BASE_URL}/back-office/competitions?${requestQuery}`
  );
  return response.data.data.competitions;
};

const CompetitionSearch = () => {
  const { themeStretch } = useSettings();
  const [competitions, setCompetitions] = useState<FullCompetition[]>([]);
  const [pagination, setPagination] = useState(initialPagination);
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const parsedDate = parsedQuery['startDate'];
  const parsedSearch = parsedQuery['search'] as string;
  const [searchQuery, setSearchQuery] = useState(parsedSearch ?? '');
  const [dateQuery, setDateQuery] = useState((parsedDate as string) ?? '');
  const { updateQuery } = useParsedQuery();
  const [filters, setFilters] = useState({
    eventType: [] as string[],
    status: [] as string[],
    level: [] as string[],
  });
  const isMountedRef = useIsMountedRef();
  const isDefaultFilter = Object.values(filters).every((filter) => !filter.length);
  const [sorting, setSorting] = useState({
    order: 'ascending' as 'ascending' | 'descending',
    orderBy: 'numberOfEntrants' as keyof CompetitionSearchSortByQuery,
  });

  useEffect(() => {
    if (isMountedRef.current) {
      (async () => {
        try {
          setPagination((prev) => ({ ...prev, loading: true }));
          const response = await fetchData({
            filters,
            startDate: tryParseDate(dateQuery) ?? undefined,
            endDate: tryParseDate(dateQuery) ?? undefined,
            limit: pagination.limit,
            page: pagination.page,
            sorting,
            search: searchQuery,
          });
          setCompetitions(response);
        } catch (error) {
          console.error('Error fetching competitions:', error);
        } finally {
          setPagination((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      })();
    }
  }, [dateQuery, filters, isMountedRef, pagination.limit, pagination.page, searchQuery, sorting]);

  const handleRequestSort = (property: string) => {
    const isAsc = sorting.orderBy === property && sorting.order === 'ascending';
    setSorting({
      order: isAsc ? 'descending' : 'ascending',
      orderBy: property as keyof CompetitionSearchSortByQuery,
    });
  };

  const handleSearchQuery = debounce((newSearchQuery: string) => {
    setPagination({ ...pagination, page: 1 });
    const dateQuery = tryParseDate(newSearchQuery);
    if (dateQuery) {
      updateQuery({ dateQuery: newSearchQuery, search: '' });
      setDateQuery(newSearchQuery);
      setSearchQuery('');
    } else {
      updateQuery({ search: newSearchQuery, dateQuery: '' });
      setSearchQuery(newSearchQuery);
      setDateQuery('');
    }
  }, 700);

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const rows = event.target.value as number;
    const pageCount = calculatePages(rows, pagination);
    setPagination({ ...pagination, page: pageCount, limit: rows });
  };

  return (
    <Page title="Competition Search">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Competitions', href: PATH_DASHBOARD.competitions.root },
          ]}
          heading={'Competitions'}
        />

        <Card>
          <ListToolbar
            searchQuery={searchQuery || dateQuery}
            onSearchQuery={handleSearchQuery}
            placeholder="Search competitions ..."
          >
            <Filters
              filters={filters}
              handleFilters={setFilters as Dispatch<SetStateAction<FilterType>>}
              setPagination={setPagination}
              filterType={'competitionSearch'}
            />
          </ListToolbar>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  headLabel={COMPETITION_SEARCH_TABLE_HEAD}
                  order={sorting.order}
                  orderBy={sorting.orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableWrapper
                  loading={pagination.loading}
                  searchQuery={searchQuery}
                  listLength={!!competitions?.length}
                  isDefaultFilter={isDefaultFilter}
                  colSpan={6}
                >
                  <CompetitionSearchTable competitions={competitions} />
                </TableWrapper>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TableFooter
            pagination={pagination}
            handleRows={handleChangeRowsPerPage}
            setPagination={setPagination}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default CompetitionSearch;
