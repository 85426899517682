import {
  CardReaderType,
  CardReaderUI,
  ICardReader,
  ICardReaderConfiguration,
} from '../../../@types/cardReader';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { loadCardReader } from './cardReaders/cardReaderFactory';
import { useGameCoordinatorContext } from './GameCoordinatorStateProvider';
import { LocalStorageKeys, getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';
import { useAppLogger } from 'utils/logging/LoggerProvider';

interface Props {
  children: React.ReactElement;
}

export interface CardReaderContextValue {
  selectedCardReaderType: CardReaderType;
  isConfigured: boolean;
  isValidationDone: boolean;
  isCardReaderLoaded: boolean;
  isCardReaderInitialized: boolean;
  currentConfiguration: ICardReaderConfiguration | null;
  CardReaderUIComponent?: CardReaderUI | null;
  onCardReaderTypeChanged: (cardReaderType: CardReaderType) => void;
  onConfigurationComplete: () => void;
  onValidationComplete: () => void;
  configureCardReader: () => void;
  initializeCardReader: () => void;
  shutDownCardReader: (isClosing: boolean) => void;
}

const initialState = {
  selectedCardReaderType: CardReaderType.NONE,
  isConfigured: false,
  isValidationDone: false,
  isCardReaderLoaded: false,
  isCardReaderInitialized: false,
  currentConfiguration: null,
  onCardReaderTypeChanged: (cardReaderType: CardReaderType) => {},
  onConfigurationComplete: () => {},
  onValidationComplete: () => {},
  configureCardReader: () => {},
  initializeCardReader: () => {},
  shutDownCardReader: (isClosing: boolean) => {},
};

const CardReaderContext = createContext<CardReaderContextValue>(initialState);

export const CardReaderStateProvider = ({ children }: Props) => {
  const logger = useAppLogger();
  const [selectedCardReaderType, setSelectedCardReaderType] = useState<CardReaderType>(
    () => getFromLocalStorage(LocalStorageKeys.SELECTED_CARD_READER_TYPE) ?? CardReaderType.NONE
  );
  const [isConfigured, setIsConfigured] = useState<boolean>(
    () => getFromLocalStorage(LocalStorageKeys.IS_CARD_READER_CONFIGURED) ?? false
  );
  const [isValidationDone, setIsValidationDone] = useState<boolean>(
    () => getFromLocalStorage(LocalStorageKeys.IS_CARD_READER_VALIDATION_DONE) ?? false
  );
  const [isCardReaderLoaded, setIsCardReaderLoaded] = useState(false);
  const [isCardReaderInitialized, setIsCardReaderInitialized] = useState(false);
  const cardReader = useRef<ICardReader>();
  const { signalProcessor } = useGameCoordinatorContext();

  useEffect(() => {
    logger.log(`useEffect selectedCardReaderType = ${selectedCardReaderType}`);
    logger.log(`useEffect selectedCardReaderType: cardReader = ${JSON.stringify(cardReader)}`);
    const loadCardReaderFromConfig = () => {
      logger.log(`loadCardReaderFromConfig: ${selectedCardReaderType}`);
      if (!cardReader.current || cardReader.current?.cardReaderType !== selectedCardReaderType) {
        const loadedCardReader = loadCardReader(selectedCardReaderType, signalProcessor);
        logger.log(`  loadedCardReader = ${JSON.stringify(loadedCardReader)}`);
        cardReader.current = loadedCardReader;
        setIsCardReaderLoaded(!!cardReader.current);
      }
    };

    loadCardReaderFromConfig();
  }, [selectedCardReaderType, signalProcessor]);

  const initializeCardReader = useCallback(async () => {
    logger.log(`initializeCardReader: cardReader = ${JSON.stringify(cardReader)}`);
    logger.log(`initializeCardReader: cardReader = ${isCardReaderInitialized}`);
    const onInitializeComplete = () => {
      setIsCardReaderInitialized(true);
      logger.log(
        `onInitializeComplete: Done initializing card reader ${cardReader.current?.cardReaderType} currently isInitialized=${isCardReaderInitialized}`
      );
    };

    try {
      if (!isCardReaderInitialized) await cardReader.current?.initialize(onInitializeComplete);
    } catch {
      logger.error('Error initializing card reader - resetting configuration');
      resetConfiguration();
    }
  }, [isCardReaderInitialized]);

  const shutDownCardReader = useCallback(
    async (isClosing: boolean) => {
      logger.log(`shutDownCardReader: isClosing = ${isClosing}`);
      logger.log(`shutDownCardReader: isCardReaderInitialized = ${isCardReaderInitialized}`);
      logger.log(`shutDownCardReader: cardReader = ${JSON.stringify(cardReader)}`);
      const onShutdownComplete = () => {
        setIsCardReaderInitialized(false);
        logger.log(
          `onShutdownComplete: Done shutting down card reader ${cardReader.current?.cardReaderType} currently isInitialized=${isCardReaderInitialized}`
        );
      };
      if (isCardReaderInitialized)
        await cardReader.current?.shutDown(isClosing, onShutdownComplete);
    },
    [isCardReaderInitialized]
  );

  useEffect(() => {
    return () => {
      shutDownCardReader(true).catch((err) => {
        logger.error(`Error shutting down card reader: ${err}`);
      });
    };
  }, [shutDownCardReader]);

  const resetConfiguration = () => {
    logger.log('Resetting configuration and validation in localStorage');
    saveToLocalStorage(LocalStorageKeys.IS_CARD_READER_CONFIGURED, false);
    saveToLocalStorage(LocalStorageKeys.IS_CARD_READER_VALIDATION_DONE, false);
    setIsCardReaderLoaded(false);
    setIsConfigured(false);
    setIsValidationDone(false);
    setIsCardReaderInitialized(false);
    cardReader.current = undefined;
  };

  const stateObj = useMemo(() => {
    const onCardReaderTypeChanged = async (cardReaderType: CardReaderType) => {
      await shutDownCardReader(false);
      resetConfiguration();

      logger.log(
        `onCardReaderTypeChanged selectedCardReaderType = ${selectedCardReaderType}; new type = ${cardReaderType}`
      );
      saveToLocalStorage(LocalStorageKeys.SELECTED_CARD_READER_TYPE, cardReaderType);
      setSelectedCardReaderType(cardReaderType);
    };

    const onConfigurationComplete = () => {
      logger.log('Marking configuration done in localStorage');
      saveToLocalStorage(LocalStorageKeys.IS_CARD_READER_CONFIGURED, true);
      setIsConfigured(true);
    };
    const onValidationComplete = () => {
      logger.log('Marking validation done in localStorage');
      saveToLocalStorage(LocalStorageKeys.IS_CARD_READER_VALIDATION_DONE, true);
      setIsValidationDone(true);
    };

    const configureCardReader = async () => {
      logger.log('Configuring card reader');
      logger.log(`configureCardReader: cardReader = ${JSON.stringify(cardReader)}`);
      await cardReader.current?.configure();
      if (!(await cardReader.current?.needsConfiguration())) {
        onConfigurationComplete();
      } else {
        logger.log('Device still needs configuration');
      }
    };

    return {
      isCardReaderInitialized,
      isCardReaderLoaded,
      isConfigured,
      isValidationDone,
      selectedCardReaderType,
      currentConfiguration: JSON.stringify(cardReader.current?.deviceInterface?.configuration),
      CardReaderUIComponent: cardReader.current?.uiComponent,
      onCardReaderTypeChanged,
      onConfigurationComplete,
      onValidationComplete,
      configureCardReader,
      initializeCardReader,
      shutDownCardReader,
    };
  }, [
    isCardReaderInitialized,
    isCardReaderLoaded,
    isConfigured,
    isValidationDone,
    selectedCardReaderType,
    initializeCardReader,
    shutDownCardReader,
  ]);

  return <CardReaderContext.Provider value={stateObj}>{children}</CardReaderContext.Provider>;
};

export const useCardReaderContext = () => useContext(CardReaderContext);
