import { GameStatePlayer } from '../../../../../@types/game';
import { getValueForRank } from '../../../../../utils/cards';

interface PlayerData {
  dealtCards: CardData[];
  tablePosition: number;
  shouldGet3rdCard: boolean;
}

export interface ResponseData {
  bankerId: number;
  bankNaturalWin: boolean;
  players: PlayerData[];
}
interface CardData {
  open: boolean;
  id: string;
}

const getBankerData = (responseData: ResponseData) => {
  const { bankerId } = responseData;
  const bankerData = responseData.players.find((player) => player.tablePosition === bankerId);
  return bankerData as PlayerData;
};

const getPlayerScore = (dealtCards: CardData[]) => {
  let sum = 0;
  dealtCards.forEach((card: CardData) => {
    const cardValue = card.id.slice(-1);
    sum += getValueForRank(cardValue);
  });
  return sum % 10;
};

const isNatural = (dealtCards: CardData[]) => {
  if (dealtCards.length !== 2) return false;
  const score = getPlayerScore(dealtCards);
  return score === 8 || score === 9;
};

export const getDealingSignalsCount = (responseData: ResponseData): number => {
  let count = 0;
  const { players, bankerId } = responseData;
  players.forEach((player) => {
    if (player.tablePosition !== bankerId && player.shouldGet3rdCard) {
      count++;
    }
  });
  return count;
};

const getBankerScore = (responseData: ResponseData) => {
  const bankerData = getBankerData(responseData);
  const bankerScore = getPlayerScore(bankerData.dealtCards);
  return bankerScore;
};

export const doesPlayerDrawMap = [
  [true, true, true, true, true, true, true, true, true, true], // banker = 0
  [true, true, true, true, true, true, true, true, true, true], // banker = 1
  [true, true, true, true, true, true, true, true, true, true], // banker = 2
  [true, true, true, true, true, true, true, true, false, true], // banker = 3
  [false, false, true, true, true, true, true, true, false, false], // banker = 4
  [false, false, false, false, true, true, true, true, false, false], // banker = 5
  [false, false, false, false, false, false, true, true, false, false], // banker = 6
  [false, false, false, false, false, false, false, false, false, false], // banker = 7
  [false, false, false, false, false, false, false, false, false, false], // banker = 8
  [false, false, false, false, false, false, false, false, false, false], // banker = 9
];

export const checkResponseForBanker3rdCard = (responseData: ResponseData) => {
  const bankerScore = getBankerScore(responseData);

  const { players, bankerId } = responseData;

  return isBankerEligibleFor3rdCard(
    bankerId,
    bankerScore,
    players.map((player) => {
      return {
        tablePosition: player.tablePosition,
        dealtCards: player.dealtCards,
      };
    })
  );
};

export interface PlayerPositionDataFor3rdCardCheck {
  tablePosition: number;
  dealtCards: CardData[];
}

export const checkGameStateForBanker3rdCardEligibility = (
  bankerData: GameStatePlayer,
  playerDatas: GameStatePlayer[]
) => {
  const bankerCards = bankerData?.revealedCardDatas ?? [];
  if (bankerData.numCardsRevealed !== 2) return false;
  const bankerScore = (bankerCards[0].value + bankerCards[1].value) % 10;

  return isBankerEligibleFor3rdCard(
    bankerData.seat,
    bankerScore,
    playerDatas?.map((player) => {
      return {
        tablePosition: player.seat,
        dealtCards:
          player.revealedCardDatas?.map((card) => {
            return { id: card.code, open: true };
          }) ?? [],
      };
    }) ?? []
  );
};

export const isBankerEligibleFor3rdCard = (
  bankerId: number,
  bankerScore: number,
  players: PlayerPositionDataFor3rdCardCheck[]
) => {
  return players.some((player) => doesBankerDrawAgainstPlayer(bankerId, bankerScore, player));
};

export const doesBankerDrawAgainstPlayer = (
  bankerPos: number,
  bankerScore: number,
  player: PlayerPositionDataFor3rdCardCheck
) => {
  console.log(
    'doesBankerDrawAgainstPlayer',
    JSON.stringify({ player, bankerScore, bankerPos, isNatural: isNatural(player.dealtCards) })
  );
  if (player.tablePosition === bankerPos) return false;
  if (player.dealtCards.length === 2) return isNatural(player.dealtCards) ? false : bankerScore < 6;

  const player3rdCard = player.dealtCards[2].id.slice(-1);
  const player3rdCardValue = getValueForRank(player3rdCard);
  const result = doesPlayerDrawMap[bankerScore][player3rdCardValue];

  console.log(
    'doesBankerDrawAgainstPlayer',
    JSON.stringify({ bankerScore, player3rdCardValue, result })
  );

  return result;
};

export const allTPHaveNW = (gssData: ResponseData) => {
  const { players, bankerId } = gssData;
  return players
    .filter((player) => player.tablePosition !== bankerId)
    .every((player) => getPlayerScore(player.dealtCards) >= 8);
};
