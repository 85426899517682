import {
  GameState,
  GetGameStateQuery,
  OnGameStateUpdateSubscription,
  queries,
  subscriptions,
} from '@av-sym/avbc-packages/rp-graphql-schemas';
import { API, graphqlOperation, GraphQLQuery, GraphQLSubscription } from '@aws-amplify/api';
import { configureAmplify } from 'config';
import { checkGameStateForBanker3rdCardEligibility } from 'pages/dashboard/game-coordinator/helpers/gss/playerData';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { gssStringToCardData, notEmpty } from 'utils/cards';
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  LocalStorageKeys,
  saveToLocalStorage,
} from 'utils/localStorage';

import { BettingRoundData, GameStatePlayer, GameStateTablePlayers } from '../../../@types/game';
import { STUDIO_STATES } from './helpers/gss/consts';
import { clearSequenceNumber } from './helpers/gss/sequenceNumber';
import { Log, Signal, SIGNAL_TYPES } from './types';
import { dateTimeProvider } from '../../../utils/dateTimeProvider';
import { useAppLogger } from '../../../utils/logging/LoggerProvider';

interface Props {
  children: React.ReactElement;
}

export interface GameCoordinatorContextValue {
  processing: boolean;
  receivedSignal: Signal | null;
  logs: Log[] | null;
  studioGameState: string | null;
  storedEvent: string | null;
  handleEventSelected: (eventId: string) => void;
  handleLog: (signal: Signal) => void;
  setProcessing: Dispatch<SetStateAction<boolean>>;
  updateStudioState: (newState: string) => void;
  signalProcessor: (signal: Signal) => void;
  handleGameStateReset: () => void;
  gssGameState?: string | null; // TODO: Type for GSS state enum
  gssPlayers: GameStateTablePlayers | null;
  gssBankerData: GameStatePlayer | null;
  gssBettingRoundData: BettingRoundData | null;
  gssHandNo?: number | null;
  gssNextDealPosition?: number | null;
  gssRevealedPosition?: number | null;
  gssNextRevealPosition?: number | null;
  gssCardsRemaining?: number | null;
}

const createLog = (signal: Signal) => {
  return {
    value: signal.type ? `${signal.type}: ${signal.value}` : `${signal.value}`,
    createdOn: dateTimeProvider.nowDate().getTime(),
  };
};

const initialState = {
  processing: false,
  receivedSignal: null,
  logs: null,
  studioGameState: null,
  storedEvent: null,
  handleLog: () => {},
  setProcessing: () => {},
  handleEventSelected: () => {},
  updateStudioState: () => null,
  signalProcessor: () => null,
  handleGameStateReset: () => null,
  gssGameState: null,
  gssPlayers: null,
  gssBankerData: null,
  gssBettingRoundData: null,
  gssHandNo: null,
  gssNextDealtPosition: null,
  gssRevealedPosition: null,
  gssNextRevealPosition: null,
  gssCardsRemaining: null,
};

const GameCoordinatorContext = createContext<GameCoordinatorContextValue>(initialState);

export const GameCoordinatorStateProvider = ({ children }: Props) => {
  const logger = useAppLogger();
  const [processing, setProcessing] = useState(false);

  const [storedEvent, setStoredEvent] = useState<string | null>(() =>
    getFromLocalStorage(LocalStorageKeys.EVENT_ID)
  );
  const [receivedSignal, setReceivedSignal] = useState<Signal | null>(null);
  const [logs, setLogs] = useState<Log[] | null>(() => getFromLocalStorage(LocalStorageKeys.LOGS));
  const [studioGameState, setCroupierGameState] = useState<string | null>(() =>
    getFromLocalStorage(LocalStorageKeys.GAME_STATE)
  );
  const [incomingGssGameStateData, setIncomingGssGameStateData] = useState<
    GameState | null | undefined
  >(null);
  const [gssGameStateData, setGssGameStateData] = useState<GameState | null>(null);
  const [gssPlayers, setGssPlayers] = useState<GameStateTablePlayers | null>(null);
  const [gssBankerData, setGssBankerData] = useState<GameStatePlayer | null>(null);
  const [gssBettingRoundData, setGssBettingRoundData] = useState<BettingRoundData | null>(null);

  const parsePlayers = useCallback(
    (theGameState: GameState) => {
      if (!theGameState) return;

      const parsedPlayers = theGameState?.players ? JSON.parse(theGameState.players) : [];
      let theBanker = {} as GameStatePlayer;
      const newPlayers = parsedPlayers.reduce((acc: GameStatePlayer[], item: GameStatePlayer) => {
        const revealedCards = item?.cards?.filter((card) => card !== 'NV');
        const numRevealedCards = revealedCards?.length ?? 0;
        const numCardsDealt = item?.dealtCards?.length ?? 0;

        acc[item.seat] = {
          ...item,
          numCardsDealt,
          numCardsRevealed: numRevealedCards,
          revealedCardDatas: revealedCards
            ?.map((card) => gssStringToCardData(card))
            .filter(notEmpty),
        };
        if (item.isBanker) theBanker = { ...acc[item.seat] };
        return acc;
      }, {}) as GameStateTablePlayers;

      theBanker.shouldBankerGet3rdCard = checkGameStateForBanker3rdCardEligibility(
        theBanker,
        Object.values(newPlayers)
      );

      logger.log(
        `theBanker.shouldBankerGet3rdCard [${JSON.stringify(theBanker.shouldBankerGet3rdCard)}]`
      );

      setGssBankerData({ ...theBanker });
      setGssPlayers({ ...newPlayers });
    },
    [setGssBankerData, setGssPlayers]
  );

  const parseBettingRound = useCallback(
    (theGameState: GameState) => {
      if (!theGameState) return;

      const parsedBetRoundData = theGameState?.bettingRoundData
        ? JSON.parse(theGameState.bettingRoundData)
        : null;
      setGssBettingRoundData(parsedBetRoundData);
    },
    [setGssBettingRoundData]
  );

  useEffect(() => {
    if (!incomingGssGameStateData || incomingGssGameStateData === gssGameStateData) return;
    if (!gssGameStateData?.updatedAt) setGssGameStateData(incomingGssGameStateData);

    const newServerTimestamp = Number(incomingGssGameStateData?.updatedAt ?? 1);
    const oldServerTimestamp = Number(gssGameStateData?.updatedAt ?? 0);

    if (newServerTimestamp >= oldServerTimestamp) {
      setGssGameStateData(incomingGssGameStateData);
      parsePlayers(incomingGssGameStateData);
      parseBettingRound(incomingGssGameStateData);
      logger.log(`incomingGssGameState.state = ${JSON.stringify(incomingGssGameStateData?.state)}`);
      logger.log(
        `incomingGssGameState.nextDealPosition = ${JSON.stringify(
          incomingGssGameStateData?.nextDealPosition
        )}`
      );
      logger.log(
        `incomingGssGameState.nextRevealPosition = ${JSON.stringify(
          incomingGssGameStateData?.nextRevealPosition
        )}`
      );
    }
  }, [incomingGssGameStateData, gssGameStateData, parseBettingRound, parsePlayers]);

  useEffect(() => {
    if (!storedEvent) return;
    configureAmplify();

    logger.log('Subscribing to graphQL');
    API.graphql<GraphQLQuery<GetGameStateQuery>>(
      graphqlOperation(queries.getGameState, {
        gameSessionId: storedEvent,
      })
    ).then((reply): void => {
      const initState = reply.data?.getGameState?.items?.pop() ?? null;
      logger.log('Received init gss game state:', JSON.stringify(initState));
      setIncomingGssGameStateData(initState);
    });

    const subscription = API.graphql<GraphQLSubscription<OnGameStateUpdateSubscription>>(
      graphqlOperation(subscriptions.onGameStateUpdate, {
        gameSessionId: storedEvent,
      })
    ).subscribe({
      next: ({ value }) => {
        setIncomingGssGameStateData(value?.data?.onGameStateUpdate);
      },
      error: (error) => logger.error(error),
    });

    return () => subscription.unsubscribe();
  }, [storedEvent]);

  useEffect(() => {
    setReceivedSignal(null);
  }, []);

  useEffect(() => {
    saveToLocalStorage(LocalStorageKeys.LOGS, logs);
  }, [logs]);

  useEffect(() => {
    saveToLocalStorage(LocalStorageKeys.GAME_STATE, studioGameState);
    saveToLocalStorage(LocalStorageKeys.EVENT_ID, storedEvent);
  }, [storedEvent, studioGameState]);

  const handleLog = useCallback((signal: Signal) => {
    logger.log(`handleLog: signal = ${JSON.stringify(signal)}`);
    const log = createLog(signal);
    setLogs((prevState) => {
      if (prevState) {
        return [...prevState, log];
      }
      return [log];
    });
  }, []);

  const updateStudioState = useCallback(
    (newState: string) => {
      if (newState && newState !== studioGameState) {
        handleLog({
          type: SIGNAL_TYPES.ALERT,
          value: `Studio game state ${studioGameState} -> ${newState}`,
        });
        setReceivedSignal(null);
        setCroupierGameState(newState);
      }
    },
    [studioGameState]
  );

  const resetEventStateVariables = () => {
    setCroupierGameState(STUDIO_STATES.INITIAL_STATE);
    setProcessing(false);
    setReceivedSignal(null);
    setLogs([]);
    clearSequenceNumber();
    saveToLocalStorage(LocalStorageKeys.DEALT_CARDS_IN_ROUND, 1);
    saveToLocalStorage(LocalStorageKeys.DEALING_ROUND, 1);
    saveToLocalStorage(LocalStorageKeys.NEXT_BETTING_ROUND, 1);
    deleteFromLocalStorage(LocalStorageKeys.DEALING_SIGNALS);
    deleteFromLocalStorage(LocalStorageKeys.SUCCESS_SIGNAL_SENT);
    setGssBankerData(null);
    setGssBettingRoundData(null);
    setGssGameStateData(null);
    setGssPlayers(null);
    setIncomingGssGameStateData(null);
    logger.clearLogs();
  };

  const handleEventSelected = (eventId: string) => {
    setStoredEvent(eventId);
    resetEventStateVariables();
  };

  const handleGameStateReset = () => {
    setStoredEvent('');
    resetEventStateVariables();
  };

  const signalProcessor = useCallback(
    (signal: Signal) => {
      logger.log(`signalProcessor: Received signal [${JSON.stringify(signal)}]`);
      switch (signal.type) {
        case SIGNAL_TYPES.NEW_CARD:
        case SIGNAL_TYPES.SET_BANKER:
        case SIGNAL_TYPES.MANUAL:
          setReceivedSignal(signal);
          break;
        default:
          handleLog(signal);
      }
    },
    [handleLog]
  );

  return (
    <GameCoordinatorContext.Provider
      value={{
        processing,
        receivedSignal,
        logs,
        studioGameState,
        storedEvent,
        handleEventSelected,
        handleLog,
        setProcessing,
        updateStudioState,
        signalProcessor,
        handleGameStateReset,
        gssGameState: gssGameStateData?.state,
        gssPlayers,
        gssBankerData,
        gssBettingRoundData,
        gssHandNo: gssGameStateData?.hand,
        gssNextDealPosition: gssGameStateData?.nextDealPosition,
        gssRevealedPosition: gssGameStateData?.exposedPosition,
        gssNextRevealPosition: gssGameStateData?.nextRevealPosition,
        gssCardsRemaining: gssGameStateData?.cardsRemainingInShoe,
      }}
    >
      {children}
    </GameCoordinatorContext.Provider>
  );
};

export const useGameCoordinatorContext = () => useContext(GameCoordinatorContext);
