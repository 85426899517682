import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

interface ConfirmationDialogProps {
  handleClose: VoidFunction;
  handleSubmit: VoidFunction;
  openDialog: boolean;
  title: string;
  isSubmitting: boolean;
}
export default function ConfirmationDialog({
  handleClose,
  handleSubmit,
  openDialog,
  title,
  isSubmitting,
}: ConfirmationDialogProps) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="confirmation-dialog"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          color="primary"
          data-testid="confirmation-dialog-confirm-button"
        >
          Yes
        </LoadingButton>
        <Button
          onClick={handleClose}
          autoFocus
          color="error"
          data-testid="confirmation-dialog-cancel-button"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
