import { Outlet } from 'react-router';
import { CardReaderStateProvider } from './CardReaderStateProvider';
import { GameCoordinatorStateProvider } from './GameCoordinatorStateProvider';
import { LiveDealerHelperStateProvider } from 'components/live-dealer-helper/LiveDealerHelperStateProvider';
import { LoggerProvider } from '../../../utils/logging/LoggerProvider';

const StudioContextLayout = () => (
  <LoggerProvider options={{ id: 'game-coordinator', maxRecords: 5000 }}>
    <GameCoordinatorStateProvider>
      <LiveDealerHelperStateProvider>
        <CardReaderStateProvider>
          <Outlet />
        </CardReaderStateProvider>
      </LiveDealerHelperStateProvider>
    </GameCoordinatorStateProvider>
  </LoggerProvider>
);

export default StudioContextLayout;
