import { format, getTime, formatDistanceToNow } from 'date-fns';
import { dateTimeProvider } from './dateTimeProvider';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(dateTimeProvider.getDate(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(dateTimeProvider.getDate(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(dateTimeProvider.getDate(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(dateTimeProvider.getDate(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateWithDashes(date: Date | string | number) {
  return format(dateTimeProvider.getDate(date), 'yyyy-MM-dd');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(dateTimeProvider.getDate(date), {
    addSuffix: true,
  });
}
